import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Appearances from '../components/Appearances/Appearances'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import ColorButton from '../components/Ui/ColorButton'
import getTicketURL from '../utils/TicketURL'

const Artist = ({ pageContext, location }) => {
  const {
    title,
    main_image: mainImage,
    from,
    style,
    description,
    youtube_ids: youtubeIds,
    appearances,
    metadescription,
    metakeywords,
    metatitle,
    og_img: ogImage,
    rue_festive_only: rueFestiveOnly,
    socialmedias_csv: socialMediasCSV
  } = pageContext.post.data

  let activitesDiverses = pageContext.post.data
  activitesDiverses |= false

  const image = getImage(mainImage)
  let free = false

  appearances.data.allPrismicAppearances.edges.forEach(({ node }) => {
    const currentEvent = node.data.event.document?.data
    if (currentEvent?.free) {
      free = true
    }
  })

  const sortedAppearances = appearances.data.allPrismicAppearances.edges
  sortedAppearances.sort(function (a, b) {
    const keyA = new Date(b.node.data.event.document.event_appearances.data.allPrismicAppearances.edges[0].node.data.time)
    const keyB = new Date(a.node.data.event.document.event_appearances.data.allPrismicAppearances.edges[0].node.data.time)

    if (keyA > keyB) return -1
    if (keyA < keyB) return 1
    return 0
  })

  const uniqueAppearances = [];
  const seenUids = new Set();

  sortedAppearances.forEach((appearance) => {
    const uid = appearance?.node?.data?.event?.document?.uid;
    if (uid && !seenUids.has(uid)) {
      seenUids.add(uid);
      uniqueAppearances.push(appearance);
    }
  });

  console.log(uniqueAppearances)

  let facebook
  let instagram
  let spotify
  let website
  let apple
  let youtube
  let tiktok
  socialMediasCSV.text.split(',').forEach(function (url) {
    if (!facebook && url.includes('facebook')) {
      facebook = url
    } else if (!instagram && url.includes('instagram')) {
      instagram = url
    } else if (!spotify && url.includes('spotify')) {
      spotify = url
    } else if (!youtube && url.includes('youtube')) {
      youtube = url
    } else if (!tiktok && url.includes('tiktok')) {
      tiktok = url
    } else if (!apple && url.includes('apple')) {
      apple = url
    } else if (url.length > 5) {
      website = url
    }
  })

  let hasSocialMedias = facebook | instagram | spotify | website | apple | youtube | tiktok;
  let shortDescription = style?.text && from?.text ? style?.text + " - " + from?.text : style?.text ? style?.text : from?.text ? from?.text : ''

  function compareStartTime (appearance1, appearance2) {
    return new Date(appearance1.node.data.event.document.data.start_time_raw) - new Date(appearance2.node.data.event.document.data.start_time_raw)
  }

  const pageTitle = 'Artistes'
  return (
    <Wolflayout title={pageTitle}>

      <FestifHelmet htmlAttributes={{ lang: 'fr' }}>
        <title>
          {metatitle.text
            ? metatitle.text
            : title.text + ' - Artistes | Le Festif! de Baie-Saint-Paul'}
        </title>
        <meta name="description" content={metadescription.text} />
        <meta name="keywords" content={metakeywords.text} />
        <meta
          property="og:title"
          content={
            metatitle.text
              ? metatitle.text
              : title.text + ' - Artistes | Le Festif! de Baie-Saint-Paul'
          }
        />
        <meta property="og:description" content={metadescription.text} />
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        {ogImage ? <meta property="og:image" content={ogImage.url} /> : null}
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>
      
      <Banner title={pageTitle} />

      {/* BACK BUTTON */}
      {/* TODO: This is not working if there is no history */}
      <ColorButton text="← Retour à la liste" isPink={true} className="hover:bg-blue-wolf2025 mx-auto w-64 !mt-9 md:!mt-0" onClick={() => {
          window.history.back();
      }} />

      <div className="md:w-1/2 w-full mx-auto pt-12 px-2 md:px-16">
        <section className='border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>

          {/* YELLOW SECTION */}
          <div className='bg-yellow-wolf2025 text-center py-4 border-b-4 border-purple-wolf2025'>
          
            <h2 className={`${title?.text === 'CRi (DJ SET)' ? '' : 'uppercase'} font-obviously font-black text-purple-wolf2025 text-3xl`}>{title?.text ? title.text : ''}</h2>
            <h4 className='font-obviously font-regular text-purple-wolf2025 uppercase text-sm md:tracking-[0.25rem] tracking-widest'>{shortDescription}</h4>
          </div>

          {/* PHOTO */}
          <div className="relative">
            <div className="w-full mx-0 px-0">
              <div className="relative text-center">
                {image && (
                  <GatsbyImage
                    className=""
                    image={image}
                    alt={image.alt ? image.alt : ''} />
                )}
              </div>
            </div>
          </div>
          
          {/* APPEARANCES */}
          <div className="pb-4 flex-col text-center max-w-full mx-auto px-auto w-full bg-purple-wolf2025">
            {appearances &&
              uniqueAppearances.sort(compareStartTime).map(({ node }) => {
                return <Appearances data={node.data} numberOfAppearances={appearances.data.allPrismicAppearances.edges.length} isFree={free} />
            })}
          </div>

          {/* DESCRIPTION */}
          <div className='font-openSans font-light italic text-blue-wolf2025 bg-purple-wolf2025 text-center pb-6 pt-0'>
            <p className='px-16'>{description.text}</p>
          </div>

          {/* SOCIAL MEDIAS */}
          {
            hasSocialMedias ?
              <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
                <div className="py-2 text-center flex flex-wrap justify-center gap-0 bg-blue-wolf2025">
                  {facebook && (
                    <a href={facebook} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/facebook-purple.png"
                        alt="Logo Facebook"
                        className="social-image"
                      />
                    </a>
                  )}
                  {instagram && (
                    <a href={instagram} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/instagram-purple.png"
                        alt="Logo Instagram"
                        className="social-image"
                      />
                    </a>
                  )}
                  {website && (
                    <a href={website} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/web-purple.png"
                        alt="Logo générique de site web"
                        className="social-image"
                      />
                    </a>
                  )}
                  {spotify && (
                    <a href={spotify} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/spotify-purple.png"
                        alt="Logo Spotify"
                        className="social-image"
                      />
                    </a>
                  )}
                  {apple && (
                    <a href={apple} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/apple-purple.png"
                        alt="Logo Apple Music"
                        className="social-image"
                      />
                    </a>
                  )}
                  {tiktok && (
                    <a href={tiktok} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/tiktok-purple.png"
                        alt="Logo TikTok"
                        className="social-image"
                      />
                    </a>
                  )}
                  {youtube && (
                    <a href={youtube} target="_blank" className="social-link" rel="noreferrer">
                      <StaticImage
                        src="../images/wolf/socialmedias/youtube-purple.png"
                        alt="Logo Youtube"
                        className="social-image"
                      />
                    </a>
                  )}
                </div>
              </div>
            :
              <></>
          }

        </section>

        {
          rueFestiveOnly ?
            <></>
            :
            <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : 'w-36 mx-auto'}`}>
            {free
              ? <></>
              : <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} my-12`}>
                  <ColorButton text="Billets" href={getTicketURL()} externalLink={true} className="hover:bg-blue-wolf2025" />
                </div>
            }
            {uniqueAppearances[0]?.node?.data?.event?.document?.data?.lpdv_id?.text && uniqueAppearances[0]?.node?.data?.event?.document?.data?.lpdv_id?.text !== ''
              ? 
              <ColorButton text="Réserver" href={`https://lepointdevente.com/tickets/${uniqueAppearances[0]?.node?.data?.event?.document?.data?.lpdv_id?.text}`} externalLink={true} />
              : <></>
            }
          </div>
        }

        {/* BOUTON RUE FESTIVE */}
        {/* <div className="mt-4 mb-1 text-center flex justify-center py-2">
          {rueFestiveOnly
            ?
              <ColorButton text={"VOIR L'HORAIRE DE LA RUE FESTIVE"} href={'/infos/ruefestive#info_content'} innerClassName="px-2" />
            : <></>
            }
        </div> */}

        {/* YOUTUBE */}
        {
          youtubeIds ?
            <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} mt-12 relative w-full pt-[56.25%]`}>
            <iframe
              title={title && youtubeIds ? title.text + youtubeIds.text : ''}
              className="absolute top-0 left-0 w-full h-full mb-3rem"
              src={youtubeIds && youtubeIds.text ? 'https://www.youtube.com/embed/' + youtubeIds.text : ''}
              allowFullScreen=""
              referrerPolicy="origin-when-cross-origin"
              height={'100%'}
            ></iframe>
          </div>
          :
          <></>
        }

      </div>
    </Wolflayout>
  )
}

export default Artist
